import $ from 'jquery';
import { i18n, warn } from '../common/helpers';

class Reason {
  constructor($element, {
    name, index, reasons, commentMaxLength, isHidden, isOrder, additionalClass = '', callbacks = {},
  }) {
    if (!$element.length) {
      warn('Reason requires an element');
      return;
    }

    this.$el = $element;
    this.name = name;
    this.index = index;
    this.reasons = reasons;
    this.commentMaxLength = commentMaxLength;
    this.isHidden = isHidden;
    this.isOrder = isOrder;
    this.additionalClass = additionalClass;
    this.callbacks = callbacks;

    this.$disclaimer = this.$el.find('.js-disclaimer');
    this.$disclaimerTitle = this.$disclaimer.find('.js-disclaimer-title');
    this.$disclaimerText = this.$disclaimer.find('.js-disclaimer-text');

    this.groups = [{
      type: 'action',
      group: this.$el.find('.reasons__action'),
    }, {
      type: 'reason',
      group: this.$el.find('.reasons__reason'),
    }, {
      type: 'wish',
      group: this.$el.find('.reasons__wish'),
    }, {
      type: 'comment',
      group: this.$el.find('.reasons__comment'),
    }];

    $('body').on('input change', '.js-free_input_field', function inputComment() {
      const $count = $(this).closest('.reasons__comment').find('.reasons__comment-length');
      const { length } = $(this).val();
      $count.toggleClass('reasons__comment-length_exceeded', length > commentMaxLength).find('.reasons__comment-length-value').text(length);
    });

    if (this.reasons) {
      // New reason module
      this.createNextField(this.reasons, 0, this.$el.data('reasons'));
    }
  }

  getValue() {
    const value = {};

    this.$el.find(`.${Reason.INPUT}`).each(function inputEach() {
      value[$(this).data('type')] = $(this).val();
    });

    return value;
  }

  createNextField(newData, index, initial) {
    if (typeof newData.is_additional !== 'undefined') {
      // Get last group for additional product info
      const $additionalGroup = this.groups[this.groups.length - 1].group;
      $additionalGroup
        // Hide additional group with only hidden fields
        .toggleClass('order__group_comment_empty', !(newData.free_input_field && newData.is_additional))
        .append(this.createExtra(newData, initial));
    } else {
      let $input;
      let $options = $();
      const { group, type } = this.groups[index];

      Object.keys(newData).forEach((key) => {
        if (key === 'shown_name') return;
        $options = $options.add(`<option value="${key}">${newData[key].shown_name}</option>`);
      });

      if (this.isHidden) {
        $input = this.createHidden(type);
      } else {
        $input = this.createDropdown(type).append($options);
        this.$disclaimer.addClass('hidden');
      }

      if ($input.attr('id')) {
        $(`<label class="field__label" for="${$input.attr('id')}">${$input.data('label')}<span class="asterisk"></span></label>`).appendTo(group);
      }
      $input.appendTo(group)
        .on(`change${Reason.NAMESPACE}`, this.change(index + 1, newData));

      if (this.callbacks[type]) {
        $input.on(`change${Reason.NAMESPACE}`, this.callbacks[type]);
      }

      if (initial) {
        $input.val(initial[type]).trigger(`change${Reason.NAMESPACE}`, [initial]);
      } else if ($options.length === 1) {
        $input.val($options.val()).trigger(`change${Reason.NAMESPACE}`).attr('tabindex', '-1');
        // For the popup on step2
        $input.closest('.reason-popup__group').addClass('reasons__action_no-tab').prev('.reason-popup__group_label').hide();
        // For the form on step3
        $input.closest('.order__group').addClass('reasons__action_no-tab').prev('.order__group_label').hide();
      }
    }
  }

  change(index, data) {
    const self = this;

    return function changeHandler(event, initial) {
      // It is an event handler, so 'this' refers to dropdown
      self.clearGroups(index);
      self.createNextField(data[$(this).val()], index, initial);
    };
  }

  /**
   * Remove content in groups with index equal or greater than parameter
   * @param {number} index
   */
  clearGroups(index) {
    for (let i = index; i < this.groups.length; i += 1) {
      this.groups[i].group.empty();
    }
  }

  createDropdown(type) {
    const { 'data-msg-required': msg, 'aria-label': label } = Reason.getFieldData(type);
    const options = {
      class: `input input_select field__input ${this.additionalClass} ${Reason.INPUT} js-${type}`,
      id: `${this.name}[${this.index}][${type}]`,
      name: `${this.name}[${this.index}][${type}]`,
      'data-type': type,
      'data-msg-required': msg,
      'data-label': label,
      required: true,
    };

    return $('<select>', options).append(`<option disabled selected>${i18n('Not selected')}</option>`);
  }

  createHidden(type, name = this.name, value) {
    const options = {
      class: `${Reason.INPUT} js-${type}`,
      name: `${name}[${this.index}][${type}]`,
      'data-type': type,
      type: 'hidden',
      value,
    };

    return $('<input>', options);
  }

  static getFieldData(type) {
    switch (type) {
      case 'action':
        return {
          'data-msg-required': i18n('Action is required'),
          'aria-label': i18n('Action'),
        };
      case 'reason':
        return {
          'data-msg-required': i18n('Reason is required'),
          'aria-label': i18n('Reason'),
        };
      case 'wish':
        return {
          'data-msg-required': i18n('Wish is required'),
          'aria-label': i18n('Wish'),
        };
      default:
        return {};
    }
  }

  createExtra(options, initial = {}) {
    let $extra = $();

    if (options.is_additional) {
      if (options.free_input_field) {
        if (this.isHidden) {
          $extra = $extra.add(this.createHidden('free_input_field', this.name, initial.free_input_field));
        } else {
          $extra = $extra.add(`<label class="field__label" for="${this.name}[${this.index}][free_input_field]">${options.free_input_title}${options.required ? '<span class="asterisk"></span>' : ''}</label>`);
          $extra = $extra.add($('<input>', {
            class: `input ${this.additionalClass} field__input ${Reason.INPUT} js-free_input_field`,
            id: `${this.name}[${this.index}][free_input_field]`,
            name: `${this.name}[${this.index}][free_input_field]`,
            'data-type': 'free_input_field',
            'data-msg-required': i18n('Comment is required'),
            'data-rule-maxlength': this.commentMaxLength,
            'data-msg-maxlength': i18n('Comment is too long. Maximum length is {0} symbols.'),
            type: 'text',
            value: initial.free_input_field,
            required: options.required,
          }));
          $extra = $extra.add(`<small class="reasons__comment-length"><span class="reasons__comment-length-value">${initial.free_input_field ? initial.free_input_field.length : '0'}</span> / ${this.commentMaxLength}</small>`);
        }

        $extra = $extra.add(this.createHidden('free_input_title', this.name, options.free_input_title));
      }

      const disclaimerTitle = options.disclaimer_title?.trim() ?? '';
      const disclaimerText = options.disclaimer_text?.trim() ?? '';
      const hasDisclaimer = options.disclaimer && (disclaimerTitle || disclaimerText);

      this.$disclaimerTitle.text(disclaimerTitle);
      this.$disclaimerText.text(disclaimerText);
      this.$disclaimer.toggleClass('hidden', !hasDisclaimer);
    }

    $extra = $extra.add(this.createHidden('reason_code', this.isOrder ? 'item_returned_reasons_additional' : this.name, options.reason_code));
    $extra = $extra.add(this.createHidden('reason_category', this.isOrder ? 'item_returned_reasons_additional' : this.name, options.reason_category));

    return $extra;
  }
}

Reason.NAMESPACE = '.reason';
Reason.INPUT = 'js-reason-input';

export default Reason;
